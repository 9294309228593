import { gql } from "@apollo/client";
const CREATE_NEWS = gql`
  mutation createNews(
    $title: String!
    $postText: String!
    $category: String
    $platform: Int
    $youtubeVideoLink: String
    $author: Int!
    $federationNews: [Int!]
    $clubNews: [Int!]
    $leagueNews: [Int!]
    $date: DateTime
    $image: Upload
    $isDisplayLanding: Boolean
    $metaTags: String
  ) {
    createNews(
      createNewsInput: {
        title: $title
        postText: $postText
        category: $category
        platform: $platform
        youtubeVideoLink: $youtubeVideoLink
        author: $author
        federationNews: $federationNews
        clubNews: $clubNews
        leagueNews: $leagueNews
        image: $image
        date: $date
        isDisplayLanding: $isDisplayLanding
        metaTags: $metaTags
      }
    ) {
      title
      id
    }
  }
`;
export default CREATE_NEWS;
