import { gql } from "@apollo/client";

const FEDERATIONS = gql`
  query federations($filter: ListFederationInput, $arrayOfPlatform: [Int!]) {
    federations(filter: $filter, arrayOfPlatform: $arrayOfPlatform) {
      federations {
        user {
          name
          surname
          email
          phone
          id
          surname
          role
        }
        organizationName
        organizationAbbreviation
        status
        clubs {
          id
          clubName
        }
        leagues {
          id
          league {
            id
            title
          }
        }
        platforms {
          platform {
            id
            title
          }
        }
        logo
        about
        contactEmail
        contactPhone
        website
        id
      }
    }
  }
`;

export default FEDERATIONS;
