import { gql } from "@apollo/client";

const CLUBS = gql`
  query clubs(
    $page: Int!
    $limit: Int!
    $sport: String
    $filter: ListClubInput
  ) {
    clubs(page: $page, limit: $limit, sport: $sport, filter: $filter)
  }
`;

export default CLUBS;
