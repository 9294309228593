import { gql } from "@apollo/client";

const PLATFORM_COACH = gql`
  query platformCoach($id: Int!) {
    platformCoach(id: $id) {
      id
      user {
        id
        email
      }
      category
      dateFrom
      dateTo
      sport {
        id
      }
      clubs {
        id
        clubName
      }
      status
      displayOnLandingPage
      userRole
    }
  }
`;

export default PLATFORM_COACH;
