import { gql } from "@apollo/client";

const THIRD_PARTY_LICENSE = gql`
  query thirdPartyLicenses($filter: ListThirdPartyLicenseInput!) {
    thirdPartyLicenses(filter: $filter) {
      id
      title
      sports {
        id
        title
        icon
      }
      organizationName
      organizationAbbreviation
      type
    }
  }
`;

export default THIRD_PARTY_LICENSE;
