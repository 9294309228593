import { gql } from "@apollo/client";

const ALLLEAGUESNAMES = gql`
  query allLeaguesNames($filter: ListLeagueInput!) {
    allLeaguesNames(filter: $filter) {
      title
      type
      status
      platform {
        id
        title
      }
      sports {
        id
        title
        icon
      }
      owner {
        federation {
          organizationName
        }
      }
      email
      website
      id
      logo

      abbreviationLogo
      athlete {
        athlete {
          email
          dob
        }
      }
      tournaments {
        id
      }
    }
  }
`;

export default ALLLEAGUESNAMES;
