import { gql } from "@apollo/client";

export const PLATEFORM = gql`
  query platforms($page: Int, $limit: Int, $filter: ListPlatformInput) {
    platforms(page: $page, limit: $limit, filter: $filter) {
      platforms {
        id
        organization {
          id
          federation {
            organizationName
            id
          }
        }
        country
        description
        platformType
        status
        title
        sports {
          id
          sport {
            id
            title
            icon
          }
        }
        language
        country
        coverPhoto
        lightLogo
        companyName
        companyEmail
        onlinePayments
        liveScoring
      }
      total
    }
  }
`;
