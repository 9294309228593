import { gql } from "@apollo/client";

const ADD_GALLERY = gql`
  mutation createAlbum(
    $title: String!
    $albumCustomEvent: String
    $albumTags: String
    $date: String
    $isSpecificTournament: Boolean
    $isAlbumPublic: Boolean
    $thumbnail: Upload
    $league: Int
    $tournament: Int
    $platform: Int
  ) {
    createAlbum(
      createAlbumInput: {
        title: $title
        albumCustomEvent: $albumCustomEvent
        albumTags: $albumTags
        date: $date
        isSpecificTournament: $isSpecificTournament
        isAlbumPublic: $isAlbumPublic
        thumbnail: $thumbnail
        league: $league
        tournament: $tournament
        platform: $platform
      }
    ) {
      id
    }
  }
`;
export default ADD_GALLERY;
