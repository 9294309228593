import { gql } from "@apollo/client";

const REMOVE_CATEGORY_FROM_GROUP = gql`
  mutation removeCategoriesFromTournamentCategoryGroup(
    $tournamentCategoryGroupId: Int!
    $tournamentCategoryIds: [Int!]!
  ) {
    removeCategoriesFromTournamentCategoryGroup(
      removeTournamentCategoriesInput: {
        tournamentCategoryGroupId: $tournamentCategoryGroupId
        tournamentCategoryIds: $tournamentCategoryIds
      }
    ) {
      id
    }
  }
`;

export default REMOVE_CATEGORY_FROM_GROUP;
