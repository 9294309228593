import { gql } from "@apollo/client";

const CREATE_PURCHASED_LICENSE = gql`
  mutation createPurchasedLicense(
    $paid: Float!
    $currency: String!
    $user: Int
    $license: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $type: String!
    $userData: CreateUserInput
    $payment_status: LicensePaymentStatus
    $addedBy: Int!
    $basePrice: Float!
  ) #$platformId: Float
  {
    createPurchasedLicense(
      createPurchasedLicenseInput: {
        paid: $paid
        currency: $currency
        user: $user
        license: $license
        startDate: $startDate
        endDate: $endDate
        type: $type
        userData: $userData
        payment_status: $payment_status
        addedBy: $addedBy
        basePrice: $basePrice
      }
    ) #platformId: $platformId
    {
      id
    }
  }
`;

export default CREATE_PURCHASED_LICENSE;
