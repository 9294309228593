import React, {
  cloneElement,
  ComponentProps,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Box, Popover } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CustomModal from "./CustomModal/CustomModal";

type Props = {
  // triggerEl must accept ref!
  triggerEl: ReactElement | ((open: boolean) => ReactElement);
  children: ((onClose: () => void) => ReactNode) | ReactNode;
  preventClosing?: (() => void) | boolean;
  popoverProps?: Partial<ComponentProps<typeof Popover>>;
  onClose?: () => void;
  onOpen?: () => void;
  modalOnMobile?: boolean;
  align?: "left" | "right" | "center";
  activated?: boolean;
  disablePadding?: boolean;
};

const CustomPopover = ({
  triggerEl,
  children,
  preventClosing,
  popoverProps,
  onClose,
  modalOnMobile,
  onOpen,
  disablePadding,
  activated = true,
  align = "left",
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const isMobile = modalOnMobile && useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    if (preventClosing) {
      if (typeof preventClosing === "function") preventClosing();
      return;
    }

    setOpen(false);
    onClose?.();
  };

  useEffect(() => {
    if (open) onOpen?.();
  }, [open]);

  return activated ? (
    !isMobile ? (
      <>
        {cloneElement(
          typeof triggerEl === "function" ? triggerEl(open) : triggerEl,
          { ref: setAnchorEl, onClick: () => setOpen(true) }
        )}
        {anchorEl && !isMobile && (
          <Popover
            anchorEl={anchorEl}
            onClose={handleClose}
            open={open}
            PaperProps={{
              sx: {
                borderRadius: "6px",
                padding: disablePadding ? 0 : "10px",
              },
              elevation: 1,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: align,
            }}
            transformOrigin={{ vertical: -10, horizontal: align }}
            {...popoverProps}
            marginThreshold={0}
          >
            <Box
              sx={{
                flex: 1,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {typeof children === "function"
                ? children(handleClose)
                : children}
            </Box>
          </Popover>
        )}
      </>
    ) : (
      <CustomModal
        triggerEl={triggerEl}
        onClose={onClose}
        onOpen={onOpen}
        preventClosing={preventClosing}
      >
        {children}
      </CustomModal>
    )
  ) : (
    <>{children as ReactNode}</>
  );
};

export default CustomPopover;
