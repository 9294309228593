import { gql } from "@apollo/client";

const UPDATE_MEDIA = gql`
  mutation updateMedia($id: Int!, $photoCaption: String, $date: String) {
    updateMedia(
      updateMediaInput: { id: $id, photoCaption: $photoCaption, date: $date }
    ) {
      id
    }
  }
`;

export default UPDATE_MEDIA;
