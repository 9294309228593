import { gql } from "@apollo/client";

const ADD_Transaction = gql`
  mutation createTransactionHistory(
    $createTransactionHistoryInput: CreateTransactionHistoryInput!
  ) {
    createTransactionHistory(
      createTransactionHistoryInput: $createTransactionHistoryInput
    ) {
      id
    }
  }
`;

export default ADD_Transaction;
