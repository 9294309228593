import { gql } from "@apollo/client";

const GET_ALL_GALLERIES = gql`
  query findAllAlbum(
    $filter: ListAlbumInput
    $platform: Int
    $page: Int
    $limit: Int
    $organization: Int
  ) {
    findAllAlbum(
      filter: $filter
      platform: $platform
      page: $page
      limit: $limit
      organization: $organization
    ) {
      albums {
        id
        title
        thumbnail
        isSpecificTournament
        albumCustomEvent
        albumTags
        date
        media {
          id
        }
        league {
          id
          title
        }
        tournament {
          id
          title
        }
      }
      total
    }
  }
`;

export default GET_ALL_GALLERIES;
