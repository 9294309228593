import { gql } from "@apollo/client";

const TOURNAMENT_CATEGORIES_GROUPS = gql`
  query findAllTournamentCategoryGroup(
    $filter: ListTournamentCategoryGroupInput
  ) {
    findAllTournamentCategoryGroup(filter: $filter) {
      id
      name
      tournamentCategories {
        id
        name
        type
        status
      }
    }
  }
`;

export default TOURNAMENT_CATEGORIES_GROUPS;
