import { gql } from "@apollo/client";

const DELETE_MEDIA_LIST = gql`
  mutation removeMediaList($ids: [Int!]!) {
    removeMediaList(ids: $ids) {
      createdAt
    }
  }
`;

export default DELETE_MEDIA_LIST;
