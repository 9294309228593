import { gql } from "@apollo/client";

const DELETE_MEDIA = gql`
  mutation removeMedia($id: Int!) {
    removeMedia(id: $id) {
      createdAt
    }
  }
`;

export default DELETE_MEDIA;
