import { gql } from "@apollo/client";

const SPORTS = gql`
  query sports($platform: Int, $filter: ListSportInput, $ids: [Int!]) {
    sports(platform: $platform, filter: $filter, ids: $ids) {
      id
      title
      icon
      abbreviation
      enableDiscipline
      enableCourts
      enableStages
      displayDraws
      displayGroups
      displayLiveStandings
      displayOrderPlay
      displayListResults
      displayPendingResults
      displayTournamentResults
      textBox
      discipline {
        id
        title
      }
    }
  }
`;

export default SPORTS;
