import { Box, Button, Typography } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import React from "react";
import useAuth from "src/hooks/useAuth";

interface NotFoundProps {
  btnTitle?: string;
  noText?: string;
  link?: any;
  pageTitle?: string;
}

const NotFoundComp = (props: NotFoundProps) => {
  const auth = useAuth();
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "600" }} variant="h2">
            {props?.pageTitle}
          </Typography>
        </Box>
        {props.btnTitle && (
          <Button
            size="small"
            color="primary"
            // disabled={auth.leagueId ? false : true}
            startIcon={
              <ReactSVG
                src="/images/addw.svg"
                style={{
                  marginTop: "4px",
                  paddingLeft: "5px",
                }}
              />
            }
            sx={{
              height: "36px",
              textTransform: "uppercase",
            }}
            variant="contained"
            onClick={props.link}
          >
            {props.btnTitle}
          </Button>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h2" sx={{ py: 10 }}>
          {props.noText}
        </Typography>
      </Box>
    </React.Fragment>
  );
};
export default NotFoundComp;
