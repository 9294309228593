import { gql } from "@apollo/client";

const CREATE_LEAGUE_CATEGORY = gql`
  mutation createCategory(
    $name: String!
    $leagueId: Float!
    $gender: String!
    # $uniqueCode: String!
    $maxTournaments: Float
    $activeFrom: DateTime
    $activeTo: DateTime
    $sort: String!
    $ageLimit: Float
    $ageLimitType: String
    $selectStage: String
    $countries: String!
    $tournamentCategories: [Int!]
    $ageFrom: Int
    $ageTo: Int
    $timePeriod: String
    $countAllTournament: Boolean
    $tournaments: [Int!]
    $fixedPoints: Int
    $penalty: Boolean
    $penaltyRule: String
    $specificTournament: Boolean
    $rules: [JSONObject!]
    $isDisplayClub: Boolean
    $onlyThirdPartyRanking:Boolean
    $isDisplayCity: Boolean
    $isDisplayBirthYear: Boolean
    $enableLicense: Boolean
    $licenses: [Int!]
  ) {
    createCategory(
      createCategoryInput: {
        name: $name
        leagueId: $leagueId
        gender: $gender
        # uniqueCode: $uniqueCode
        maxTournaments: $maxTournaments
        activeFrom: $activeFrom
        activeTo: $activeTo
        sort: $sort
        ageLimit: $ageLimit
        ageLimitType: $ageLimitType
        selectStage: $selectStage
        tournamentCategories: $tournamentCategories
        countries: $countries
        timePeriod: $timePeriod
        countAllTournament: $countAllTournament
        ageFrom: $ageFrom
        ageTo: $ageTo
        fixedPoints: $fixedPoints
        penalty: $penalty
        penaltyRule: $penaltyRule
        specificTournament: $specificTournament
        tournaments: $tournaments
        rules: $rules
        isDisplayClub: $isDisplayClub
        onlyThirdPartyRanking:$onlyThirdPartyRanking
        isDisplayCity: $isDisplayCity
        isDisplayBirthYear: $isDisplayBirthYear
        enableLicense: $enableLicense
        licenses: $licenses
      }
    ) {
      id
      name
      gender
      # uniqueCode
      maxTournaments
      activeFrom
      activeTo
      sort
      ageLimit
      ageLimitType
      selectStage
      countries
    }
  }
`;

export default CREATE_LEAGUE_CATEGORY;
