import { gql } from "@apollo/client";

const CATEGORY_BY_ID = gql`
  query category($id: Int!) {
    category(id: $id) {
      id
      name
      type
      gender
      uniqueCode
      maxTournaments
      activeFrom
      activeTo
      sort
      ageLimit
      ageLimitType
      ageFrom
      ageTo
      selectStage
      countries
      timePeriod
      fixedPoints
      countAllTournament
      specificTournament
      enableLicense
      licenses {
        id
        title
      }
      categoryRules {
        id
        sector
        numberOfTournaments
        coefficient
        thirdPartyLicense {
          id
          title
        }
        licenseSubtypes
        tournamentCategories {
          id
          name
        }
      }
      tournaments {
        id
        tournament {
          id
          title
        }
      }
      penalty
      penaltyRule
      isDisplayClub
      onlyThirdPartyRanking
      isDisplayCity
      isDisplayBirthYear
      tournamentCatgories {
        id
        tournamentCategory {
          name
          type
          gender
          order
          discipline {
            id
          }
          enableMetrics
          team
          isAllowed
          moveToSingles
          categoryEntries
          enableLicense
          status
          ageLimit
          ageType
          league {
            id
            league {
              title
            }
          }
          id
        }
        rankingCategory {
          name
        }
      }
    }
  }
`;

export default CATEGORY_BY_ID;
