import { gql } from "@apollo/client";

const CREATE_CATEGORY_GROUPS = gql`
  mutation createTournamentCategoryGroup(
    $CreateTournamentCategoryGroupInput: CreateTournamentCategoryGroupInput!
  ) {
    createTournamentCategoryGroup(
      createTournamentCategoryGroupInput: $CreateTournamentCategoryGroupInput
    ) {
      id
    }
  }
`;
export default CREATE_CATEGORY_GROUPS;
