import { useEffect } from "react";
import type { FC } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Breadcrumbs, Link, Typography } from "@material-ui/core";
import gtm from "../../lib/gtm";

import { DisciplineForm } from "src/components/dashboard/disciplines";
import { useTranslation } from "react-i18next";

const DisciplineCreate: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard: Discipline Create</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          border: "1px solid rgb(237, 241, 246)",
          px: { sm: "24px", xs: "10px" },
          my: 4,
          pt: 2,
          borderRadius: "8px",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" separator={"/"}>
          <Link
            color="textPrimary"
            component={RouterLink}
            to="/dashboard"
            variant="subtitle2"
          >
            {t("Dashboard")}
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to="/dashboard/disciplines"
            variant="subtitle2"
          >
            {t("Disciplines")}
          </Link>
          <Typography color="textSecondary" variant="subtitle2">
            {id ? t("Edit") : t("Add new Discipline")}
          </Typography>
        </Breadcrumbs>
        <Box>
          <DisciplineForm />
        </Box>
      </Box>
    </>
  );
};

export default DisciplineCreate;
