import { gql } from "@apollo/client";

const TOURNAMENTS = gql`
  query allTournamentNames(
    $organization: Int
    $page: Int
    $limit: Int
    $filter: ListTournamentInput
    $platform: Int
  ) {
    allTournamentNames(
      organization: $organization
      page: $page
      limit: $limit
      filter: $filter
      platform: $platform
    )
  }
`;

export default TOURNAMENTS;
