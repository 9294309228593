import { gql } from "@apollo/client";

const UPDATE_GALLERY = gql`
  mutation updateAlbum(
    $id: Int!
    $title: String
    $thumbnail: Upload
    $isSpecificTournament: Boolean
    $isAlbumPublic: Boolean
    $albumCustomEvent: String
    $albumTags: String
    $date: String
    $league: Int
    $tournament: Int
  ) {
    updateAlbum(
      updateAlbumInput: {
        id: $id
        title: $title
        thumbnail: $thumbnail
        isSpecificTournament: $isSpecificTournament
        isAlbumPublic: $isAlbumPublic
        albumCustomEvent: $albumCustomEvent
        albumTags: $albumTags
        date: $date
        league: $league
        tournament: $tournament
      }
    ) {
      id
    }
  }
`;

export default UPDATE_GALLERY;
