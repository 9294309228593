import { gql } from "@apollo/client";

const FEDERATION_BY_ID = gql`
  query federation($id: Int!, $platform: Int) {
    federation(id: $id, platform: $platform) {
      user {
        id
        name
        surname
        email
        phone
        instagram
      }
      platforms {
        customFee {
          id
          currency
          rangeStart
          rangeEnd
          feeType
          serviceType
          tournatedFee
          tournatedMinFee
          tournatedMaxFee
          partnerFee
          partnerMinFee
          partnerMaxFee
          isMonetization
          isManagerPayingServiceFee
        }
        platform {
          id
          title
          sports {
            sport {
              id
              title
            }
          }
          customFee {
            id
            currency
            rangeStart
            rangeEnd
            feeType
            serviceType
            tournatedFee
            tournatedMinFee
            tournatedMaxFee
            partnerFee
            partnerMinFee
            partnerMaxFee
            isMonetization
            isManagerPayingServiceFee
          }
        }
      }
      country
      organizationName
      organizationAbbreviation
      organizationType
      logo
      about
      contactEmail
      contactPhone
      status
      website
      id
    }
  }
`;

export default FEDERATION_BY_ID;
