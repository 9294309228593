import { gql } from "@apollo/client";

const GALLERY_BY_ID = gql`
  query album($id: Int!) {
    album(id: $id) {
      id
      title
      thumbnail
      isSpecificTournament
      isAlbumPublic
      albumCustomEvent
      date
      albumTags
      media {
        id
        photoLink
        photoCaption
        date
      }
      league {
        id
        title
      }
      tournament {
        id
        title
      }
    }
  }
`;

export default GALLERY_BY_ID;
