import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import type { User } from "../types/user";
import Cookies from "js-cookie";
import { useLazyQuery } from "@apollo/client";
import { ALLLEAGUESNAMES, ME, ROLES } from "src/graphql/queries";
import toast from "react-hot-toast";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  leagueId: number | string;
  sportId: number | string;
  platformData: any;

  platformId: number | string;
  platformRelationalId: number | string;
  organizationId: number | string;
  organizationUserId: number | string;
  leagues: any;
  roles: any;
  platformLanguages: string;
}

interface AuthContextValue extends State {
  platform: "JWT";
  login: (user: any) => Promise<void>;
  logout: () => Promise<void>;
  register: (user: any) => Promise<void>;
  getLeagueId: (id: number | string) => Promise<void>;
  getPlatformLanguages: (languages: string) => Promise<void>;
  getPlatformData: (platformData: any) => Promise<void>;
  getSportId: (id: number | string) => Promise<void>;
  getPlatformId: (id: number | string) => Promise<void>;
  getPlatformRelationalId: (id: number | string) => Promise<void>;
  getOrganizationId: (id: number | string) => Promise<void>;
  getOrganizationUserId: (id: number | string) => Promise<void>;

  getLeaguesData: (data: any) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type RoleAction = {
  type: "ROLES";
  payload: {
    roles: any | null;
  };
};
type LeagueAction = {
  type: "LEAGUES";
  payload: {
    leagues: any | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    user: User;
  };
};
type leagueAction = {
  type: "GET_LEAGUE_ID";
  payload: {
    id: number;
  };
};
type platformLanguageAction = {
  type: "GET_PLATFORM_LANGUAGE";
  payload: {
    languages: string;
  };
};
type platformDataAction = {
  type: "GET_PLATFORM_DATA";
  payload: {
    platformData: any;
  };
};

type sportAction = {
  type: "GET_SPORT_ID";
  payload: {
    id: number;
  };
};

type platformAction = {
  type: "GET_PLATFORM_ID";
  payload: {
    id: number;
  };
};
type platformRelationalAction = {
  type: "GET_PLATFORM_RELATIONAL_ID";
  payload: {
    id: number;
  };
};

type organizationAction = {
  type: "GET_ORGANIZATION_ID";
  payload: {
    id: number;
  };
};
type organizationUserIdAction = {
  type: "GET_ORGANIZATION_USER_ID";
  payload: {
    id: number;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | leagueAction
  | platformLanguageAction
  | platformDataAction
  | sportAction
  | platformAction
  | platformRelationalAction
  | organizationAction
  | RoleAction
  | LeagueAction
  | organizationUserIdAction;
const leagueIdString = Cookies.get("leagueId");
const sportIdString = Cookies.get("sportId");

const platformIdString = Cookies.get("platformId");
const platformRelationalIdString = Cookies.get("platformRelationalId");

const organizationIdString = Cookies.get("organizationId");
const organizationUserIdString = Cookies.get("organizationUserId");

const initialState: State = {
  isAuthenticated: Cookies.get("isAuthenticated")
    ? JSON.parse(Cookies.get("isAuthenticated")!)
    : false,

  user: Cookies.get("user") ? JSON.parse(Cookies.get("user")!) : null,
  isInitialized: false,
  leagueId:
    leagueIdString !== "undefined" && leagueIdString
      ? JSON.parse(leagueIdString)
      : null,
  sportId:
    sportIdString !== "undefined" && sportIdString
      ? JSON.parse(sportIdString)
      : null,

  platformId:
    platformIdString !== "undefined" && platformIdString
      ? JSON.parse(platformIdString)
      : null,
  platformRelationalId:
    platformRelationalIdString !== "undefined" && platformRelationalIdString
      ? JSON.parse(platformRelationalIdString)
      : null,
  organizationId:
    organizationIdString !== "undefined" && organizationIdString
      ? JSON.parse(organizationIdString)
      : null,

  organizationUserId:
    organizationUserIdString !== "undefined" && organizationUserIdString
      ? JSON.parse(organizationUserIdString)
      : null,

  platformData: null,

  platformLanguages: Cookies.get("platformLanguage"),
  // platformId: Cookies.get("platformId")
  //   ? JSON.parse(Cookies.get("platformId")!)
  //   : null,
  roles: [] as any,
  // platformId: "",
  leagues: [] as any,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  ROLES: (state: State, action: RoleAction): State => {
    const { roles } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      roles,
    };
  },
  LEAGUES: (state: State, action: LeagueAction): State => {
    const { leagues } = action.payload;

    return {
      ...state,
      leagues: leagues,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    leagueId: null,
    sportId: null,
    platformLanguages: "",
    platformData: "",
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  GET_LEAGUE_ID: (state: State, action: any): any => {
    const { id } = action.payload;

    return {
      ...state,
      leagueId: id,
    };
  },
  GET_PLATFORM_DATA: (state: State, action: any): any => {
    const { platformData } = action.payload;

    return {
      ...state,
      platformData,
    };
  },

  GET_PLATFORM_LANGUAGE: (state: State, action: any): any => {
    const { languages } = action.payload;

    return {
      ...state,
      platformLanguages: languages,
    };
  },

  GET_SPORT_ID: (state: State, action: any): any => {
    const { id } = action.payload;

    return {
      ...state,
      sportId: id,
    };
  },

  GET_ORGANIZATION_ID: (state: State, action: any): any => {
    const { id } = action.payload;

    return {
      ...state,
      organizationId: id,
    };
  },
  GET_ORGANIZATION_USER_ID: (state: State, action: any): any => {
    const { id } = action.payload;

    return {
      ...state,
      organizationUserId: id,
    };
  },

  GET_PLATFORM_ID: (state: State, action: any): any => {
    const { id } = action.payload;

    return {
      ...state,
      platformId: id,
    };
  },
  GET_PLATFORM_RELATIONAL_ID: (state: State, action: any): any => {
    const { id } = action.payload;

    return {
      ...state,
      platformRelationalId: id,
    };
  },
};
const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getLeagueId: () => Promise.resolve(),
  getPlatformLanguages: () => Promise.resolve(),
  getSportId: () => Promise.resolve(),
  getPlatformId: () => Promise.resolve(),
  getPlatformRelationalId: () => Promise.resolve(),
  getOrganizationId: () => Promise.resolve(),
  getOrganizationUserId: () => Promise.resolve(),
  getLeaguesData: () => Promise.resolve(),
  getPlatformData: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth] = useLazyQuery(ME);
  const [roles] = useLazyQuery(ROLES);
  const [getLeagues, { loading }] = useLazyQuery(ALLLEAGUESNAMES, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = Cookies.get("token") as string;
        if (accessToken) {
          const response = await auth();
          const res = await roles();
          dispatch({
            type: "ROLES",
            payload: {
              roles: res.data.userRoles,
            },
          });

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user: response.data.getProfile,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);
  // const leaguesData = async (data): Promise<void> => {
  //   const accessToken = Cookies.get("token") as string;
  //   if (accessToken) {
  //     let variables;
  //     try {
  //       variables = {
  //         filter: {
  //           platform: data,
  //         },
  //       };
  //       // const res = await roles();
  //       let res = await getLeagues({
  //         variables,
  //       });

  //       dispatch({
  //         type: "LEAGUES",
  //         payload: {
  //           leagues: res.data?.allLeaguesNames,
  //         },
  //       });
  //     } catch (err) {
  //       toast.error(err.message);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (initialState.platformId) {
  //     leaguesData(initialState.platformId);
  //   }
  // }, [initialState.platformId]);

  const login = async (user: any): Promise<void> => {
    Cookies.set("isAuthenticated", JSON.stringify(true));
    Cookies.set("token", user.accessToken);
    Cookies.set("user", JSON.stringify(user));

    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const logout = async (): Promise<void> => {
    Cookies.remove("user");
    Cookies.set("isAuthenticated", JSON.stringify(false));
    Cookies.remove("token");
    Cookies.remove("leagueId");
    Cookies.remove("sportId");
    Cookies.remove("platformId");
    Cookies.remove("platformRelationalId");
    Cookies.remove("organizationId");
    Cookies.remove("organizationUserId");
    Cookies.remove("platformLanguages");
    Cookies.remove("lang");

    dispatch({ type: "LOGOUT" });
    dispatch({
      type: "GET_LEAGUE_ID",
      payload: {
        // @ts-ignore
        id: "",
      },
    });
    dispatch({
      type: "GET_PLATFORM_LANGUAGE",
      payload: {
        // @ts-ignore
        languages: "",
      },
    });

    dispatch({
      type: "GET_SPORT_ID",
      payload: {
        // @ts-ignore
        id: "",
      },
    });

    dispatch({
      type: "GET_PLATFORM_ID",
      payload: {
        // @ts-ignore
        id: "",
      },
    });
    dispatch({
      type: "GET_PLATFORM_RELATIONAL_ID",
      payload: {
        // @ts-ignore
        id: "",
      },
    });
  };
  const getLeaguesData = async (data: any): Promise<void> => {
    dispatch({
      type: "LEAGUES",
      payload: {
        leagues: data,
      },
    });
  };

  const getLeagueId = async (id: number): Promise<void> => {
    Cookies.set("leagueId", id);
    dispatch({
      type: "GET_LEAGUE_ID",
      payload: {
        id,
      },
    });
  };
  const getPlatformLanguages = async (languages: string): Promise<void> => {
    Cookies.set("platformLanguages", languages);
    dispatch({
      type: "GET_PLATFORM_LANGUAGE",
      payload: {
        languages,
      },
    });
  };
  const getPlatformData = async (platformData: any): Promise<void> => {
    dispatch({
      type: "GET_PLATFORM_DATA",
      payload: {
        platformData,
      },
    });
  };

  const getSportId = async (id: number): Promise<void> => {
    Cookies.set("sportId", id);
    dispatch({
      type: "GET_SPORT_ID",
      payload: {
        id,
      },
    });
  };

  const getOrganizationId = async (id: number): Promise<void> => {
    Cookies.set("organizationId", id);
    dispatch({
      type: "GET_ORGANIZATION_ID",
      payload: {
        id,
      },
    });
  };
  const getOrganizationUserId = async (id: number): Promise<void> => {
    Cookies.set("organizationUserId", id);
    dispatch({
      type: "GET_ORGANIZATION_USER_ID",
      payload: {
        id,
      },
    });
  };

  const getPlatformId = async (id: number): Promise<void> => {
    Cookies.set("platformId", id);
    dispatch({
      type: "GET_PLATFORM_ID",
      payload: {
        id,
      },
    });
  };
  const getPlatformRelationalId = async (id: number): Promise<void> => {
    Cookies.set("platformRelationalId", id);
    dispatch({
      type: "GET_PLATFORM_RELATIONAL_ID",
      payload: {
        id,
      },
    });
  };
  const register = async (user: any): Promise<void> => {
    // Cookies.set("isAuthenticated", JSON.stringify(true));
    // Cookies.set("token", user.accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        register,
        getLeagueId,
        getPlatformLanguages,
        getSportId,
        getPlatformId,
        getPlatformRelationalId,
        getOrganizationId,
        getLeaguesData,
        getOrganizationUserId,
        getPlatformData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
