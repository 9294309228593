import {
  ButtonBase as MUIButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { ComponentProps, CSSProperties, forwardRef } from "react";
import { SxProps } from "@material-ui/system";
import chroma from "chroma-js";

type Sizes = {
  xs: SxProps;
  small: SxProps;
  medium: SxProps;
  large: SxProps;
  auto: SxProps;
};

type Shapes = {
  [name: string]: {
    styles?: SxProps;
    sizes: Sizes;
  };
};

const shapes: Shapes = {
  circle: {
    styles: {
      borderRadius: "100%",
    },
    sizes: {
      xs: {
        maxHeight: "16px",
        minHeight: "16px",
        maxWidth: "16px",
        minWidth: "16px",
      },
      small: {
        maxHeight: "20px",
        minHeight: "20px",
        maxWidth: "20px",
        minWidth: "20px",
      },
      medium: {
        maxHeight: "35px",
        minHeight: "35px",
        maxWidth: "35px",
        minWidth: "35px",
      },
      large: {
        maxHeight: "48px",
        minHeight: "48px",
        maxWidth: "48px",
        minWidth: "48px",
      },
      auto: {
        height: "auto",
        aspectRatio: "1/1",
      },
    },
  },
  chip: {
    styles: { borderRadius: "999px" },
    sizes: {
      xs: {
        height: "24px",
        minWidth: "24px",
        padding: "6px",
        fontSize: "12px",
      },
      small: {
        height: "32px",
        minWidth: "32px",
        padding: "10px",
        fontSize: "13px",
      },
      medium: {
        height: "40px",
        minWidth: "40px",
        padding: "10px",
        fontSize: "14px",
      },
      large: {
        height: "48px",
        minWidth: "48px",
        padding: "12px",
        fontSize: "15px",
      },
      auto: {
        height: "auto",
        fontSize: "14px",
      },
    },
  },
  default: {
    sizes: {
      xs: {
        height: "24px",
        minWidth: "24px",
        padding: "6px",
        borderRadius: "4px",
        fontSize: "12px",
      },
      small: {
        height: "32px",
        minWidth: "32px",
        padding: "10px",
        borderRadius: "6px",
        fontSize: "13px",
      },
      medium: {
        height: "40px",
        minWidth: "40px",
        padding: "10px",
        borderRadius: "8px",
        fontSize: "14px",
      },
      large: {
        height: "48px",
        minWidth: "48px",
        padding: "12px",
        borderRadius: "10px",
        fontSize: "15px",
      },
      auto: {
        height: "auto",
        borderRadius: "6px",
        fontSize: "14px",
      },
    },
  },
};

type Styles = (color: string) => {
  outline: SxProps;
  text: SxProps;
  primary: SxProps;
  secondary: SxProps;
  transparent: SxProps;
  ghost: SxProps;
  dark: SxProps;
  white: SxProps; // Add white variant
  menu: SxProps; // Add menu variant
};

const styles: Styles = (color) => {
  const theme = useTheme();
  const primaryColor = color ?? theme.palette.primary.main;

  return {
    outline: {
      backgroundColor: "#fff",
      border: "1px solid rgba(10, 37, 64, 0.15)",
      transition: "all 0.2s",
      color: "#86909F",
      "& > svg *": {
        fill: "#86909F",
      },
      ".icon": {
        backgroundColor: "#86909F",
      },
      ":hover, &.selected": {
        borderColor: primaryColor,
        color: primaryColor,
        "& > svg *": {
          fill: primaryColor,
        },
        ".icon": {
          backgroundColor: primaryColor,
        },
      },
      ":active": {
        opacity: 0.9,
      },
      ".MuiTouchRipple-child": {
        backgroundColor: primaryColor,
      },
      ":disabled": {
        borderColor: "#D5D5D5",
        "svg *": {
          fill: "#D5D5D5",
        },
        ".icon": {
          backgroundColor: "#D5D5D5",
        },
        color: "#D5D5D5",
        filter: "grayscale(100%)",
        pointerEvents: "none",
      },
      ":focus-visible": {
        outline: `2px solid ${primaryColor}`,
      },
    },
    primary: {
      backgroundColor: primaryColor,
      transition: "all 0.2s",
      color: "#fff",
      "& > svg *": {
        fill: "#fff",
      },
      ".icon": {
        backgroundColor: "#fff",
      },
      ":hover, &.selected": {
        backgroundColor: chroma(primaryColor).darken(0.35).hex(),
      },
      ":active": {
        backgroundColor: chroma(primaryColor).darken(0.6).hex(),
      },
      ".MuiTouchRipple-child": {
        backgroundColor: primaryColor,
      },
      ":disabled": {
        filter: "grayscale(100%)",
        pointerEvents: "none",
      },
      ":focus-visible": {
        outline: `2px solid ${primaryColor}`,
      },
    },
    text: {
      transition: "all 0.2s",
      color: "#0A2540",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      height: "24px",
      lineHeight: { sm: "24px", xs: "20px" },
      ":hover, &.selected": {
        opacity: 1,
      },
      ":active": {
        opacity: 0.6,
      },
      ":disabled": {
        opacity: 0.7,
        filter: "grayscale(100%)",
        pointerEvents: "none",
      },
      ":focus-visible": {
        outline: `2px solid ${primaryColor}`,
      },
    },
    secondary: {
      backgroundColor: "#fff",
      border: "1px solid rgba(10, 37, 64, 0.15)",
      transition: "all 0.2s",
      color: "#0F2927",
      "& > svg *": {
        fill: "#0F2927",
      },
      ".icon": {
        backgroundColor: "#0F2927",
      },
      ":hover, &.selected": {
        borderColor: primaryColor,
        color: primaryColor,
        "& > svg *": {
          fill: primaryColor,
        },
        ".icon": {
          backgroundColor: primaryColor,
        },
      },
      ":active": {
        opacity: 0.6,
      },
      ".MuiTouchRipple-child": {
        backgroundColor: primaryColor,
      },
      ":disabled": {
        borderColor: "#D5D5D5",
        "svg *": {
          fill: "#D5D5D5",
        },
        ".icon": {
          backgroundColor: "#D5D5D5",
        },
        color: "#D5D5D5",
        filter: "grayscale(100%)",
        pointerEvents: "none",
      },
      ":focus-visible": {
        outline: `2px solid ${primaryColor}`,
      },
    },
    transparent: {
      backgroundColor: "transparent", // No background
      border: "none", // No border
      transition: "all 0.2s",
      color: "#0F2927", // Same text color as secondary
      "& > svg *": {
        fill: "#0F2927", // Same SVG fill color as secondary
      },
      ".icon": {
        backgroundColor: "#0F2927", // Same background color as secondary
      },
      ":hover, &.selected": {
        color: primaryColor,
        "& > svg *": {
          fill: primaryColor,
        },
        ".icon": {
          backgroundColor: primaryColor,
        },
      },
      ":active": {
        opacity: 0.6,
      },
      ".MuiTouchRipple-child": {
        backgroundColor: primaryColor,
      },
      ":disabled": {
        color: "#D5D5D5",
        "svg *": {
          fill: "#D5D5D5",
        },
        ".icon": {
          backgroundColor: "#D5D5D5",
        },
        filter: "grayscale(100%)",
        pointerEvents: "none",
      },
      ":focus-visible": {
        outline: `2px solid ${primaryColor}`,
      },
    },
    ghost: {
      backgroundColor: "#fff",
      transition: "all 0.2s",
      border: "1px solid transparent",
      color: "#86909F",
      "& > svg *": {
        fill: "#86909F",
      },
      ".icon": {
        backgroundColor: "#86909F",
      },
      ":hover, &.selected": {
        border: "1px solid " + primaryColor,
        color: primaryColor,
        "& > svg *": {
          fill: primaryColor,
        },
        ".icon": {
          backgroundColor: primaryColor,
        },
      },
      ":active": {
        opacity: 0.6,
      },
      ".MuiTouchRipple-child": {
        backgroundColor: primaryColor,
      },
      ":disabled": {
        borderColor: "#D5D5D5",
        "svg *": {
          fill: "#D5D5D5",
        },
        ".icon": {
          backgroundColor: "#D5D5D5",
        },
        color: "#D5D5D5",
        filter: "grayscale(100%)",
        pointerEvents: "none",
      },
      ":focus-visible": {
        outline: `2px solid ${primaryColor}`,
      },
    },
    dark: {
      backgroundColor: "#202020",
      border: "1px solid rgba(255, 255, 255, 0.05)",
      transition: "all 0.2s",
      color: "#FFFFFF59",
      fontFamily: '"Eudoxus Sans", "Secondary Font"',
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      "& > svg *": {
        fill: "#FFFFFF59",
      },
      ".icon": {
        backgroundColor: "#FFFFFF59",
      },
      ":hover, &.selected": {
        backgroundColor: chroma("#202020").darken(0.15).hex(),
      },
      ":active": {
        backgroundColor: chroma("#202020").darken(0.25).hex(),
      },
      ".MuiTouchRipple-child": {
        backgroundColor: "#202020",
      },
      ":disabled": {
        filter: "grayscale(100%)",
        pointerEvents: "none",
      },
      ":focus-visible": {
        outline: `2px solid ${primaryColor}`,
      },
    },
    white: {
      backgroundColor: "#fff",
      transition: "all 0.2s",
      fontFamily: "Inter, sans-serif",
      fontFize: "12px",
      fontStyle: "normal",
      fontWeight: "600",
      color: "#000",
      "& > svg *": {
        fill: "#000",
      },
      ".icon": {
        backgroundColor: "#000",
      },
      ":hover, &.selected": {
        backgroundColor: chroma("#fff").darken(0.35).hex(),
      },
      ":active": {
        backgroundColor: chroma("#fff").darken(0.6).hex(),
      },
      ".MuiTouchRipple-child": {
        backgroundColor: "#fff",
      },
      ":disabled": {
        filter: "grayscale(100%)",
        pointerEvents: "none",
      },
      ":focus-visible": {
        outline: `2px solid ${primaryColor}`,
      },
    },
    menu: {
      fontWeight: "500",
      backgroundColor: "transparent",
      transition: "all 0.2s",
      color: "#86909F",
      fontSize: "13px",
      lineHeight: "100%",
      "svg *": {
        fill: "#86909F !important",
      },
      ".icon": {
        backgroundColor: "#86909F",
      },
      ":hover, &.selected": {
        backgroundColor: chroma(primaryColor).alpha(0.15).hex(),
        color: primaryColor,
        "svg *": {
          fill: primaryColor + " !important",
        },
        ".icon": {
          backgroundColor: primaryColor,
        },
      },
      ":active": {
        backgroundColor: chroma(primaryColor).alpha(0.15).hex(),
      },
      ".MuiTouchRipple-child": {
        backgroundColor: primaryColor,
      },
      ":disabled": {
        filter: "grayscale(100%)",
        pointerEvents: "none",
      },
    },
  };
};

export const getButtonStyles = (
  shape: keyof Shapes,
  size: keyof Sizes,
  variant: keyof ReturnType<Styles>,
  square?: boolean,
  color?: string
): SxProps => {
  const baseStyles = {
    ...shapes[shape]?.styles,
    ...shapes[shape]?.sizes[size],
    ...styles(color)[variant],
  } as CSSProperties;
  if (square) {
    return {
      ...baseStyles,
      minWidth: baseStyles.height,
      maxWidth: baseStyles.height,
      minHeight: baseStyles.height,
      maxHeight: baseStyles.height,
    };
  }
  return baseStyles;
};

type CustomButtonProps = Omit<ComponentProps<typeof MUIButton>, "variant"> & {
  shape?: keyof Shapes;
  size: keyof Sizes;
  variant: keyof ReturnType<Styles>;
  selected?: boolean;
  square?: boolean;
  color?: string;
  error?: boolean;
  helperText?: string;
};

const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    {
      color,
      shape = "default",
      size,
      variant,
      selected,
      square,
      helperText,
      error,
      ...props
    },
    ref
  ) => {
    const baseStyles = getButtonStyles(shape, size, variant, square, color);

    return (
      <MUIButton
        {...props}
        ref={ref}
        disableRipple={variant === "text"}
        sx={{
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Inter, sans-serif",
          lineHeight: "28px",
          ...baseStyles,
          ...props.sx,
          ...(error
            ? { "&, &:hover, &.selected": { color: "red", borderColor: "red" } }
            : {}),
        }}
        className={selected ? "selected" : undefined}
      >
        {props.children}
        {helperText && (
          <Typography
            variant="font21"
            sx={{
              pointerEvents: "none",
              position: "absolute",
              right: "24px",
              paddingInline: "4px",
              bottom: "-8px",
              background: "white",
              color: error ? "red" : "inherit",
            }}
          >
            {helperText}
          </Typography>
        )}
      </MUIButton>
    );
  }
);

export default CustomButton;
