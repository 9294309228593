import { gql } from "@apollo/client";

const CLUB_BY_ID = gql`
  query club($id: Int!) {
    club(id: $id) {
      country
      id
      clubName
      logo
      country
      city
      address
      contactEmail
      howToFindUs
      contactPhone
      instagramLink
      facebookLink
      twitterLink
      tikTok
      linkedin
      website
      isAthleteClub
      status
      description
      members {
        id
        user {
          id
          name
        }
        userRole
      }
      federation {
        id
      }
      platform {
        id
      }
      coaches {
        id
        user {
          id
          name
        }
        clubs {
          id
          clubName
        }
      }
      # clubCoaches {
      #  id
      #  coach {
      #   id
      #  name
      # email
      # }
      #}
      clubMainAdministrator {
        id
        name
        email
      }
      # clubAdministrators {
      # id
      #administrator {
      #  id
      # name
      # email
      #}
      #}
      clubServices {
        id
        services {
          id
          service
          type
        }
      }
    }
  }
`;

export default CLUB_BY_ID;
