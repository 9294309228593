import { gql } from "@apollo/client";

const UPDATE_TOURNAMENT_CATEGORY_GROUP = gql`
  mutation updateTournamentCategoryGroup(
    $id: Int!
    $UpdateTournamentCategoryGroupInput: UpdateTournamentCategoryGroupInput!
  ) {
    updateTournamentCategoryGroup(
      id: $id
      updateTournamentCategoryGroupInput: $UpdateTournamentCategoryGroupInput
    ) {
      id
    }
  }
`;

export default UPDATE_TOURNAMENT_CATEGORY_GROUP;
