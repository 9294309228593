import { gql } from "@apollo/client";

const DELETE_GALLERY = gql`
  mutation removeAlbum($id: Int!) {
    removeAlbum(id: $id) {
      id
    }
  }
`;

export default DELETE_GALLERY;
