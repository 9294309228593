import { gql } from "@apollo/client";

const DELETE_CATEGORY_GROUP = gql`
  mutation deleteTournamentCategoryGroup($id: Int!) {
    deleteTournamentCategoryGroup(id: $id) {
      name
    }
  }
`;

export default DELETE_CATEGORY_GROUP;
