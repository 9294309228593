import { gql } from "@apollo/client";

const ADD_MEDIA = gql`
  mutation createMedia($album: Int, $photoLink: Upload) {
    createMedia(createMediaInput: { album: $album, photoLink: $photoLink }) {
      id
    }
  }
`;
export default ADD_MEDIA;
