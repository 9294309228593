import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Cookies from "js-cookie";
import { experimentalStyled } from "@material-ui/core/styles";
import type { AppBarProps, Theme } from "@material-ui/core";
import AccountPopover from "./AccountPopover";
import ContentSearch from "./ContentSearch";
import NotificationsPopover from "./NotificationsPopover";
import { ReactSVG } from "react-svg";
import useAuth from "src/hooks/useAuth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CreatePopover from "./CreatePopover";
import ChevronDown from "src/icons/ChevronDown";
import { ORGANIZATION_PLATFORMS } from "src/graphql/queries";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { PLATEFORM } from "src/graphql/queries/plateform";
import useDebouncedTerm from "src/hooks/useDebouncedTerm";
import LanguagePopover from "./LanguagePopover";
import { useTranslation } from "react-i18next";
import { rowPerPage } from "src/constants";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: "white",
    border: "1px solid #EFEFF0",
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
    left: "auto !important",
    right: "auto !important",
    top: "auto !important",
    width: "-webkit-fill-available",
    "@media (min-width: 1280px)": {
      height: "77px",
      paddingLeft: "12px",
    },
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #EFEFF0",
    boxShadow: "none",
    color: theme.palette.primary.contrastText,

    height: "80px",
    "@media (min-width: 1280px)": {
      paddingTop: "11px",
      paddingLeft: "12px",
    },
  }),
  zIndex: 8,
}));
// @ts-ignore
const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  // const { logout, user, getPlatformId, leaguesData } = useAuth();
  const {
    logout,
    user,
    getPlatformId,
    getOrganizationId,
    getPlatformRelationalId,
    platformId,
  } = useAuth();

  const auth = useAuth();
  const client = useApolloClient();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [platforms, setPlatforms] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchTitle, setSearchTitle] = useState("");
  const debouncedSearch = useDebouncedTerm(searchTitle, 600);
  const observer: any = useRef();
  const { loading: isLoadingPlatforms, data: plateform } = useQuery(PLATEFORM, {
    variables: {
      page: page,
      limit: rowPerPage,
      filter: {
        ...(debouncedSearch && { title: debouncedSearch }),
        status: "active",
      },
    },
    skip: auth?.user?.role !== "superAdmin", // Change here
    onCompleted: (data) => {
      setHasMore(data?.platforms?.platforms?.length > 0);
      if (!auth?.platformId) {
        getPlatformId(data?.platforms?.platforms?.[0]?.id);
      }
      setPlatforms((prevPlatforms) => {
        const newPlatforms = data?.platforms?.platforms?.filter(
          (platform) =>
            !prevPlatforms.find(
              (prevPlatform) => prevPlatform?.id === platform?.id
            )
        );
        return [...prevPlatforms, ...newPlatforms];
      });
    },
  });

  // for preselect platform
  const { loading: isLoadingParamTournament } = useQuery(PLATEFORM, {
    // Skip if platform is not included in platforms list
    skip:
      !platformId ||
      platforms.length === 0 ||
      platforms?.find((tournament: any) => tournament?.id === platformId),
    variables: {
      filter: {
        id: platformId,
      },
    },
    onCompleted: (data) => {
      setPlatforms(
        (prevPlatforms) =>
          [...prevPlatforms, ...data?.platforms?.platforms] || []
      );
    },
  });

  const { loading: isLoadingOrgPlatforms, data: OrganizationPlatforms } =
    useQuery(ORGANIZATION_PLATFORMS, {
      skip: auth?.user?.role === "superAdmin", // Change here
      variables: {
        filter: {
          federation: auth.user.id,
        },
      },
      onCompleted: (data) => {
        const activePlatforms = data?.organizationPlatforms
          .filter((item) => item?.platform?.status === "active")
          .map((item) => ({
            title: item?.platform?.title,
            id: item?.platform?.id,
          }));

        setPlatforms(activePlatforms);

        const organizationData = data?.organizationPlatforms.find(
          (item) => item?.platform?.id === auth?.platformId
        );

        if (!auth?.platformId) {
          getPlatformId(data?.organizationPlatforms?.[0]?.platform?.id);
        }
      },
    });

  const lastOptionElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (
          entries[0].isIntersecting &&
          hasMore &&
          !isLoadingPlatforms &&
          !isLoadingOrgPlatforms
        ) {
          setPage((page) => page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingPlatforms, isLoadingOrgPlatforms]
  );

  // const handleLogout = async (): Promise<void> => {
  //   try {
  //     await logout();
  //   } catch (err) {
  //     console.error(err);
  //     toast.error("Unable to logout.");
  //   }
  // };

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const selectedPlatform: any = useMemo(() => {
    if (auth?.platformId) {
      return platforms?.find(
        (platform: any) => platform.id === auth.platformId
      );
    }
  }, [auth?.platformId, platforms]);

  useEffect(() => {
    if (
      OrganizationPlatforms?.organizationPlatforms.length > 0 &&
      auth.platformId &&
      auth.user.role !== "superAdmin"
    ) {
      let organizationData = OrganizationPlatforms?.organizationPlatforms.find(
        (item) => item?.platform?.id === auth.platformId
      );
      getPlatformRelationalId(organizationData?.id);
    }
  }, [auth.platformId, OrganizationPlatforms]);

  // useEffect(() => {
  //   setPlatforms(
  //     OrganizationPlatforms?.organizationPlatforms.map((item: any) => {
  //       return {
  //         title: item?.platform?.title,
  //         id: item?.platform?.id,
  //       };
  //     })
  //   );
  // }, [OrganizationPlatforms]);

  const handleGetId = (id: number | string, index?: number) => {
    getPlatformId(id);
    let organizationData;
    if (auth.user.role === "superAdmin") {
      organizationData = platforms.find((item) => item?.id === id);
      getPlatformRelationalId(organizationData?.[0]?.id);
    } else {
      organizationData = OrganizationPlatforms?.organizationPlatforms.find(
        (item) => item?.platform?.id === id
      );
      getPlatformRelationalId(organizationData?.id);
    }
    if (location.pathname.includes("/dashboard/platforms/settings")) {
      navigate(`/dashboard/platforms/settings/${id}`);
    }
  };

  // useEffect(() => {
  //   if (platforms?.length > 0) {
  //     let findPlatform = platforms?.find(
  //       (item) => item?.id === auth?.platformId
  //     );
  //     if (!auth?.platformId || !findPlatform?.id) {
  //       getPlatformId(platforms[0].id);
  //     }
  //   }
  // }, [platforms]);

  useEffect(() => {
    if (auth.user.role === "superAdmin") {
      if (auth.platformId && platforms?.length > 0) {
        let organizationData = platforms?.find(
          (item) => item?.id === auth.platformId
        );
        getPlatformRelationalId(organizationData?.organization[0]?.id);
      }
    }
  }, [plateform, auth.platformId]);

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ height: "77px" }}>
        <Button
          size="small"
          color="primary"
          sx={{
            py: "7px",
            height: "fit-content",
            borderRadius: "4px",
            display: { lg: "block", xs: "none" },
          }}
          variant="contained"
          // onClick={props.link}
        >
          {t("Your subscription")}
        </Button>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
            ml: {
              xs: "-20px",
            },
          }}
        >
          <Box
            sx={{
              display: {
                sm: "block",
                xs: "none",
              },
            }}
          >
            <ReactSVG
              src="/images/smallLogo.svg"
              beforeInjection={(svg) => {
                svg.setAttribute("style", "height: 40px;");
              }}
            />
          </Box>
          <ReactSVG
            src="/images/ham.svg"
            className="sidebar-toggler"
            beforeInjection={(svg) => {
              svg.setAttribute("style", "height: 30px;");
            }}
          />
        </IconButton>

        <Box
          sx={{
            "@media (max-width: 1450px)": {
              ml: "15px",
            },
            ml: "58px",
            display: {
              lg: "block",
              xs: "none",
            },
          }}
        >
          <ContentSearch />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          options={platforms || []}
          sx={{
            display: {
              sm: "block",
              xs: "none",
            },
            mt: "0px",
            width: "200px",
            zIndex: 9999,
            position: "relative",
            "& .MuiOutlinedInput-root": {
              paddingRight: "30px !important",
              height: "40px",
              borderRadius: "4px",
              backgroundColor: "#FAFAFA",
              // border: `1px solid #EFEFF0 !important`,
              color: "#0A2540",
            },
            "& .MuiOutlinedInput-input": {
              padding: "0px !important",
            },
            "& .MuiOutlinedInput-root .MuiAutocomplete-input[placeholder]": {
              padding: "0px !important",
            },
          }}
          noOptionsText="No Platform found"
          onChange={(e, value) => {
            handleGetId(value?.id);
            Cookies.remove("lang");
          }}
          popupIcon={<ChevronDown />}
          value={selectedPlatform ? selectedPlatform : null}
          clearIcon={null}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) =>
            option?.title?.charAt(0)?.toUpperCase() + option?.title?.slice(1)
          }
          renderOption={(props, item) => (
            <li {...props} key={item?.id} ref={lastOptionElementRef}>
              <ListItemText>{item?.title}</ListItemText>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              placeholder="Select platform"
              onChange={(e) => {
                if (e.target.value === "") setHasMore(true);
                setSearchTitle(e.target.value);
                setPage(0);
              }}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoadingPlatforms ||
                    isLoadingOrgPlatforms ||
                    isLoadingParamTournament ? (
                      <CircularProgress
                        color="inherit"
                        size={16}
                        sx={{
                          background: "#ffff",
                          zIndex: "99",
                          marginRight: "-14px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
        <Box
          sx={{
            display: "flex",

            gap: { lg: "30px", xs: "10px" },
            alignItems: "center",
            ml: "20px",
            "@media (max-width: 1490px)": {
              ml: "10px",
            },
          }}
        >
          <Button
            size="small"
            color="primary"
            variant="contained"
            startIcon={
              <ReactSVG
                src="/images/athlete.svg"
                style={{
                  paddingLeft: "5px",
                  paddingTop: "5px",
                }}
                beforeInjection={(svg) => {
                  svg.children[0].setAttribute("style", "fill: currentColor;");
                }}
              />
            }
            onClick={() => {
              window.open(process.env.REACT_APP_CLIENT_URL);
            }}
            sx={{
              height: "40px",
              padding: "7px 14px 7px 14px",
              borderRadius: "4px",
              fontSize: "12px",
              backgroundColor: "#FAFAFA",
              color: "#425466",
              border: `1px solid #EFEFF0`,
              "&:hover": {
                backgroundColor: "#FAFAFA",
                boxShadow: "none",
              },

              display: {
                lg: "flex",
                xs: "none",
              },
            }}
          >
            <span style={{ fontSize: "14px" }}>
              {lgUp ? t("Athlete Portal") : ""}
            </span>
          </Button>

          <Box
            sx={{
              display: {
                // lg: "block",
                xs: "none",
              },
            }}
          >
            <ReactSVG src="/images/theme.svg" />
          </Box>
          <Box
            sx={{
              display: {
                // sm: "block",
                xs: "none",
              },
            }}
          >
            <NotificationsPopover />
          </Box>
        </Box>
        <Box sx={{ ml: { lg: "14px", md: "10px", xs: "0px" } }}>
          <CreatePopover />
        </Box>
        <Box
          sx={{
            ml: { md: "10px", xs: "0px" },
            display: { sm: "block", xs: "none" },
          }}
        >
          <LanguagePopover />
        </Box>

        <Box sx={{ ml: { lg: "14px", md: "10px", xs: "10px" } }}>
          <AccountPopover />
        </Box>
        <Box
          sx={{
            border: "1px solid red",
            px: 1,
            borderRadius: "4px",
            display: {
              lg: "block",
              xs: "none",
            },
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: "#EC3535", fontWeight: 500 }}
          >
            {auth?.user?.role === "federation"
              ? "Organization"
              : auth?.user?.role === "superAdmin"
              ? "superAdmin"
              : auth?.user?.role === "admin"
              ? "admin"
              : "manager"}
          </Typography>
        </Box>
        <Box
          sx={{
            pl: 4.5,
            mt: 1,
            cursor: "pointer",
            display: {
              lg: "block",
              xs: "none",
            },
          }}
          onClick={() => {
            navigate("/dashboard");
            client.resetStore();
            logout();
          }}
        >
          <ReactSVG src="/images/logout.svg" />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
