import { gql } from "@apollo/client";

const UPDATE_TOURNAMENT_CATEGORIES = gql`
  mutation updateTournamentCategories($tournamentCategories: [JSONObject!]!) {
    updateTournamentCategories(
      updateTournamentCategoryInput: {
        tournamentCategories: $tournamentCategories
      }
    ) {
      id
    }
  }
`;

export default UPDATE_TOURNAMENT_CATEGORIES;
